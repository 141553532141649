import React from 'react';
import './App.css';
import 'tailwindcss/tailwind.css';
import Header from './components/header';
import Servicios from './components/planes';
import Caracteristicas from './components/caracteristicas';
import Testimonio from './components/testimonio';
import Contacto from './components/contacto';
import Footer from './components/footer';
import Skills from './components/skills';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <>
        <Header />
        <div id="servicios">
        <Servicios />
        </div>
        <Caracteristicas />
        <div id="testimonio">
        <Testimonio />
        </div>
        <Skills />
        <div id="contacto">
        <Contacto />
        </div>
        <Footer />
        <Routes>
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/caracteristicas" element={<Caracteristicas />} />
          <Route path="/testimonio" element={<Testimonio />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/contacto" element={<Contacto />} />
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;

